import AppLink, { AppLinkProps } from "@/components/AppLink";
import gsap, { ScrollTrigger } from "@/lib/gsap";
import Image from "next/image";
import { RefObject, useEffect, useMemo, useRef } from "react";
import { useTheme } from "styled-components";
import { Container, Label, ImageContainer } from "./styles";

export type LabaledImageProps = {
  _key: string;
  label: string | null;
  image: {
    asset: {
      url: string;
      width: number;
      height: number;
    };
  } | null;
  link: {
    isExternal: boolean | null;
    externalLink: {
      title: string | null;
      url: string | null;
    };
    internalLink: AppLinkProps | null;
  } | null;
};

const LabeledImage: React.FC<LabaledImageProps> = (props) => {
  const { label: labelText, image, link } = props;
  const { url, width, height } = image?.asset || {};

  const containerRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLAnchorElement | HTMLHeadingElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const { colors } = useTheme();

  useEffect(() => {
    // Create closure around mutable ref
    const container = containerRef.current;
    const heading = headingRef.current;
    const imageContainer = imageContainerRef.current;

    const entryAnimation = () => {
      gsap.to(heading, { color: colors.red });
      gsap.to(imageContainer, { opacity: 1 });
    };
    const exitAnimation = () => {
      gsap.to(heading, { color: colors.middleGrey });
      gsap.to(imageContainer, { opacity: 0 });
    };

    ScrollTrigger.create({
      trigger: container,
      start: "top center",
      end: "bottom center",
      onEnter: entryAnimation,
      onLeave: exitAnimation,
      onEnterBack: entryAnimation,
      onLeaveBack: exitAnimation,
    });

    return () => {
      gsap.killTweensOf([heading, imageContainer]);
    };
  }, [colors]);

  const label = useMemo(() => {
    // Default heading
    let element = (
      <Label ref={headingRef as RefObject<HTMLHeadingElement>}>
        {labelText}
      </Label>
    );

    if (link?.internalLink) {
      const { internalLink } = link;
      if (internalLink.page?._type) {
        element = (
          <AppLink page={internalLink.page} params={internalLink.params}>
            <Label as="a" ref={headingRef as RefObject<HTMLAnchorElement>}>
              <h3>{internalLink?.title || labelText}</h3>
            </Label>
          </AppLink>
        );
      }
    }
    return element;
  }, [labelText, link]);

  return (
    <Container ref={containerRef}>
      {label}
      {url && width && height && (
        <ImageContainer ref={imageContainerRef}>
          <Image
            src={url}
            width={width}
            height={height}
            alt=""
            draggable={false}
          />
        </ImageContainer>
      )}
    </Container>
  );
};

export default LabeledImage;
