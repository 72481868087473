import { H2, P } from "@/lib/styles";
import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  position: relative;
  padding: 2rem;
`;

export const Label = styled(H2)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.middleGrey};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.red} !important;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 0.5rem;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
  user-select: none;
`;
