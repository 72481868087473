import { AppLinkProps } from "@/components/AppLink";
import CallToAction from "@/components/CallToAction";

type InternalLinkButton = AppLinkProps & {
  _type: "internalLink";
  title: string;
};

type ExternalLinkButton = {
  _type: "externalLink";
  title: string;
  url: string;
  blank: boolean;
};

type DownloadLinkButton = {
  _type: "downloadLink";
  title: string;
  file: {
    asset: {
      url: string;
    };
  };
};

type ProductSearchButton = {
  _type: "object";
  title: string;
  params: string;
  page: {
    _type: string;
    slug: string;
  };
};

export type ButtonProps = {
  _key: string;
  _type: "callToAction";
  icon: string;
  variant: string;
} & (
  | { action: "internalLink"; internalLink: Omit<InternalLinkButton, "_type"> }
  | { action: "externalLink"; externalLink: Omit<ExternalLinkButton, "_type"> }
  | { action: "downloadLink"; downloadLink: Omit<DownloadLinkButton, "_type"> }
  | {
      action: "productSearch";
      productSearch: Omit<ProductSearchButton, "_type">;
    }
);

const Button: React.FC<ButtonProps> = (props) => {
  const commonProps = {
    as: "a" as "a",
    key: props._key,
    icon: props.icon as any,
    variant: props.variant as any,
  };
  if (props.action === "internalLink") {
    return (
      <CallToAction
        {...commonProps}
        action="internalLink"
        internalLink={props.internalLink}
      />
    );
  } else if (props.action === "externalLink") {
    return (
      <CallToAction
        {...commonProps}
        action="externalLink"
        externalLink={props.externalLink}
      />
    );
  } else if (props.action === "downloadLink") {
    return (
      <CallToAction
        {...commonProps}
        action="downloadLink"
        downloadLink={props.downloadLink}
      />
    );
  } else if (props.action === "productSearch") {
    return (
      <CallToAction
        {...commonProps}
        action="productSearch"
        productSearch={props.productSearch}
      />
    );
  } else {
    return null;
  }
};

export default Button;
