import gsap, { SplitText } from "@/lib/gsap";
import React, { useEffect, useRef } from "react";
import Button, { ButtonProps } from "./Button";
import LabeledImage, { LabaledImageProps } from "./LabeledImage";
import {
  ButtonsContainer,
  Container,
  Excerpt,
  Heading,
  LabeledImagesContainer,
  TextContainer,
} from "./styles";
import _SplitText from "gsap/SplitText";
import { debounce } from "lodash";

type Props = {
  heading: string | null;
  excerpt: string | null;
  labeledImages: LabaledImageProps[] | null;
  buttons: ButtonProps[] | null;
};

const TextWithLabeledImages: React.FC<Props> = (props) => {
  const { heading, excerpt, labeledImages, buttons } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLHeadingElement>(null);
  const excerptRef = useRef<HTMLDivElement>(null);

  const headingTextRef = useRef<_SplitText | null>(null);
  const excerptTextRef = useRef<_SplitText | null>(null);

  useEffect(() => {
    // Create closure around mutable ref
    const container = containerRef.current;
    const heading = headingRef.current;
    const excerpt = excerptRef.current;

    // // Setup elements for animation
    headingTextRef.current = new SplitText(heading, { type: "lines", linesClass: "lines" });
    excerptTextRef.current = new SplitText(excerpt, { type: "lines", linesClass: "lines" });
    const headingText = headingTextRef.current;
    const excerptText = excerptTextRef.current;

    const revertText = debounce(() => {
      if (headingText && excerptText) {
        headingText?.revert()
        excerptText?.revert()
      }
    }, 30);

    window.addEventListener("resize", revertText);
    gsap.set([heading, excerpt], { opacity: 1.0 });

    const timeline = gsap.timeline({
      defaults: {
        duration: 0.3,
      },
      scrollTrigger: {
        toggleActions: "play pause resume reverse",
        start: "top 75%",
      },
    });

    timeline.to(container, {
      opacity: 1.0,
      y: 0,
    });
    timeline.to(
      [headingText.lines, excerptText.lines],
      {
        opacity: 1.0,
        y: 0,
        stagger: { amount: 0.3 },
      },
      "<25%"
    );
    return function cleanup() {
      window.removeEventListener("resize", revertText);
      timeline.kill();
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <TextContainer>
        <Heading ref={headingRef}>{heading}</Heading>
        {excerpt && (
          <Excerpt
            ref={excerptRef}
            dangerouslySetInnerHTML={{
              __html: excerpt.replace(/\n/g, "<br />"),
            }}
          ></Excerpt>
        )}
        <ButtonsContainer>
          {buttons?.map((button) => (
            <Button key={button._key} {...button} />
          ))}
        </ButtonsContainer>
      </TextContainer>
      <LabeledImagesContainer>
        {labeledImages?.map((item) => (
          <LabeledImage key={item._key} {...item} />
        ))}
      </LabeledImagesContainer>
    </Container>
  );
};

export default TextWithLabeledImages;
