import CallToAction from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";
import { H2, P } from "@/lib/styles";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  padding-bottom: 4rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: flex;
  }

  opacity: 0;
  transform: translateY(100px);

  &:before {
    background-color: ${({ theme }) => theme.colors.white};
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
`;

export const TextContainer = styled.div`
  margin-bottom: 3.75rem;
  position: sticky;
  align-items: flex-start;
  height: max-content;
  padding-top: 4rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex: 1;
    margin-bottom: 0;
    top: ${({ theme }) => theme.space.headerMinHeight};
  }
`;

export const LabeledImagesContainer = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.laptop} {
    flex: 1;
  }
`;

export const Heading = styled(H2)`
  margin-bottom: 1.5rem;
  opacity: 0;
  .lines {
    opacity: 0;
    transform: translateY(20px);
  }
`;
export const Excerpt = styled(P)`
  opacity: 0;
  .lines {
    opacity: 0;
    transform: translateY(20px);
  }
`;

export const PrimaryButton: React.FC<any> = (props) => (
  <CallToAction
    as="a"
    variant="primaryBoxed"
    icon={<SVGArrowRight />}
    {...props}
  />
);

export const SecondaryButton: React.FC<any> = (props) => (
  <CallToAction
    as="a"
    variant="primaryBoxedNegative"
    icon={<SVGArrowRight />}
    $transparent
    {...props}
  />
);

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;

  // & > * {
  //   opacity: 0;
  //   transform: translateY(20px);
  // }
`;
